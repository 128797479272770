var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "employee-card" }, [
    _c(
      "div",
      {
        staticClass: "employee-card__main",
        style: { "border-bottom": "none" },
        on: { click: _vm.data.click },
      },
      [
        _c("div", { staticClass: "fw500 flex bet a-center" }, [
          _c("div", {
            domProps: {
              textContent: _vm._s(
                _vm.employee.first_name + " " + _vm.employee.last_name
              ),
            },
          }),
        ]),
        _c("div", { staticClass: "employee-card__main_main" }, [
          _c("div", { staticClass: "employee-card__main_name" }, [
            _c("div", {
              staticClass: "grey",
              domProps: { textContent: _vm._s(_vm.$t("Code")) },
            }),
            _c("div", { domProps: { textContent: _vm._s(_vm.employee.code) } }),
          ]),
          _c("div", { staticClass: "employee-card__main_name" }, [
            _c("div", {
              staticClass: "grey",
              domProps: { textContent: _vm._s(_vm.$t("Phone")) },
            }),
            _c("div", {
              domProps: { textContent: _vm._s(_vm.employee.phone) },
            }),
          ]),
          _c("div", { staticClass: "employee-card__main_name" }, [
            _c("div", {
              staticClass: "grey",
              domProps: { textContent: _vm._s(_vm.$t("Code")) },
            }),
            _c("div", { domProps: { textContent: _vm._s(_vm.employee.code) } }),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }